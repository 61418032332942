import $ from 'jquery';

export default function Nav() {
    $(".js-burger__navigation").on("click", () => {
        $(".js-burger__navigation, body").toggleClass("active");
        $(".navbar-nav").toggleClass("active");
    })

    $(window).on('orientationchange, resize, load', function () {
        if ($(window).width() <= 991) {
            $(".js-burger__navigation").removeClass("active");
            $(".navbar-nav").removeClass("active");

            $(".js-burger__navigation").on("click", () => {
                $(".js-burger__navigation").toggleClass("mobileActive");
                $(".navbar-nav").toggleClass("mobileActive");
            })
        }
    });
}
