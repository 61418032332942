
import $ from 'jquery'
import 'slick-carousel'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Slider() {
    const slider = $(".testimonials-slider");
    slider && slider.slick({
        dots: true,
        fade: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        cssEase: 'ease-in-out',
        slidesToShow: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(window).on('resize orientationchange', function () {
        slider.slick('resize');
    });
}
