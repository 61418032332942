/** HELPERS */
import './Helpers/Polyfill';

/** STYLES */
import '../scss/Index.scss';

/** COMMON */
import FullPage from './Common/FullPage';
import ScrollToSection from './Common/ScrollTo';
import Slider from './Common/Slider';
import Cookies from './Common/Cookies';
import Faq from './Common/Faq';
import Nav from './Common/Nav';
class IndexController {
    constructor (){
        new ScrollToSection();
        new Slider();
        new FullPage();
        new Cookies();
        new Faq();
        new Nav();
    }
}

// Loader babel polyfill only if needed
const BROWSER_UNSUPPORT_FEATURES = !window.Promise || !window.fetch || !window.Symbol || !Object.assign;

if (BROWSER_UNSUPPORT_FEATURES && typeof __jsVars !== 'undefined' && typeof __jsVars.dist !== 'undefined' && typeof __jsVars.dist.babelPolyfill !== 'undefined') {
    const js = document.createElement('script');
    js.src = __jsVars.dist.babelPolyfill;
    js.onload = function() {
        new IndexController();
    };
    js.onerror = function() {
        new IndexController();
        new Error('Failed to load script ' + __jsVars.dist.babelPolyfill);
    };
    document.head.appendChild(js);
} else {
    document.addEventListener('DOMContentLoaded', () => {
        new IndexController();
    });
}

