
export default function Cookies() {
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
        else return null;
    }

    const banner = document.getElementById("cookies");
    const accept = document.getElementById("cookies-accept");
    const decline = document.getElementById("decline");


    accept && accept.addEventListener("click", () => {
        document.cookie = `cookies_policy=isSet; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toGMTString()}; path=/`;
        banner.classList.remove("active");
    });

    decline && decline.addEventListener("click", () => {
        banner.classList.remove("active");
    });

    if (getCookie("cookies_policy") === null) {
        setTimeout(() => {banner && banner.classList.add("active")}, "3000");
    }
}
