export default function ScrollToSection() {
    const scrollTo = (element) => {
        window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop
        });
    }

    const button = document.querySelector(".section__next-section");
    const target = document.getElementById("caseStudies");

    button && button.addEventListener("click", (e) => {
        e.preventDefault();
            scrollTo(target);
    });
}
