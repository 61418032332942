import fullpage from 'fullpage.js';

export default function FullPage() {
    const body = document.querySelector("body");
    if(body.classList.contains('single-case_study')) {
        new fullpage('#fullpage-case-study', {
            licenseKey: '5B00ED4C-9C7142F6-99E27EB1-4291B9B4',
            autoScrolling: true,
            scrollOverflow: true,
            verticalCentered: true,
        });
    }
}
